<template>
  <v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xs ? 374 : 500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :fab="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" v-bind="attrs" v-on="on" rounded dark color="primary">
        <span class="hidden-xs-only">Создать</span>
        <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card shaped class="pa-3">
      <v-toolbar elevation="5" class="row no-gutters justify-center" dense color="primary">
        <v-toolbar-title>Создать</v-toolbar-title>
      </v-toolbar>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-text-field v-model="item.name"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Название"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="item.key"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Ключ"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="item.priority"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Порядок"/>
        </v-col>
      </v-row>
      <v-row class="justify-space-around pb-3">
        <v-btn rounded outlined elevation="3" color="error" @click="dialog = false">Отменить</v-btn>
        <v-btn rounded outlined elevation="3" color="primary" @click="save">Сохранить</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {uuid} from "uuidv4";

export default {
  data() {
    return {
      item: {
        id: uuid.Nil,
        name: "",
        key: "",
        priority: 0
      },
      dialog: false
    }
  },

  methods: {
    save() {
      this.item.priority = parseInt(this.item.priority);
      this.$store.dispatch("tags/save", Object.assign({}, this.item));
      this.dialog = false;
    }
  }
}
</script>
