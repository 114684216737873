<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-toolbar-title class="row justify-center">Категории заведений</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-2">
      <v-col cols="10">
        <v-text-field v-model="search"
                      prepend-inner-icon="mdi-magnify"
                      label="Поиск"
                      hide-details
                      dense
                      rounded
                      outlined />
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center d-flex align-center">
        <new-tag />
      </v-col>
    </v-row>
    <v-row dense class="hidden-xs-only">
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption pl-4">Изображение</span>
      </v-col>
      <v-col cols="4" class="row no-gutters justify-center">
        <span class="text-caption">Название</span>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-center">
        <span class="text-caption">Ключ</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">Порядок</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption pr-6">Редактировать</span>
      </v-col>
    </v-row>
    <v-row :class="$vuetify.breakpoint.xs ? 'pa-2' : 'py-1'" no-gutters class="ma-1 grey lighten-4" v-for="(cat, index) in filteredList" :key="cat.id">
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Изображение:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <v-avatar size="36px">
          <img alt="Category image" v-if="cat.image" :src="cat.image" @click="$refs.image[index].click()"/>
          <img alt="" v-else src="/12.jpg" @click="$refs.image[index].click()"/>
        </v-avatar>
        <div v-show=false>
          <input id="image"
                 ref="image"
                 accept="image/*"
                 type="file"
                 @change="handleImageUpload(index, cat)"/>
        </div>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Название:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="text-body-2">{{ cat.name }}</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Ключ:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="text-body-2">{{ cat.key }}</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Порядок:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="text-body-2">{{ cat.priority }}</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Редактировать:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-space-around justify-md-space-around justify-sm-space-around d-flex align-center py-1">
        <edit :tag="cat"/>
        <v-btn :class="$vuetify.breakpoint.xs ? '' : 'mr-6'" :small="$vuetify.breakpoint.mdAndUp" :x-small="$vuetify.breakpoint.xsOnly" fab @click="del(cat)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
   </v-card>
</template>

<script>
import edit from "./edit.vue";
import newTag from "./new.vue";
export default {
  components: { edit, newTag },
  data() {
    return {
      search: ""
    }
  },
  computed: {
    list() {
      return this.$store.getters["tags/list"]
    },
    filteredList() {
      if (!this.search) {
        return this.list;
      }
      return this.list.filter(row => row.name.toLowerCase().includes(this.search));
    }
  },

  created() {
    this.$store.dispatch("tags/fetch");
  },

  methods: {
    handleImageUpload(index, cat) {
      let file = this.$refs.image[index].files[0];
      if (file){
        this.$store.dispatch("tags/upload" , {id: cat.id, file: file});
      }
    },
    del(cat) {
      this.$confirm(
          "Вы точно хотите удалить категорию",
          "Внимание",
          "question"
      ).then(() => {
        this.$store.dispatch("tags/remove", cat);
      })
    }
  }
}
</script>

